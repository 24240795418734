// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\00079_Waldeck_Stadtverwaltung\\00079_1435_Klosterrundgang_Ober-Werbe\\Landingpage\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-content-js": () => import("D:\\00079_Waldeck_Stadtverwaltung\\00079_1435_Klosterrundgang_Ober-Werbe\\Landingpage\\src\\templates\\content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-start-template-js": () => import("D:\\00079_Waldeck_Stadtverwaltung\\00079_1435_Klosterrundgang_Ober-Werbe\\Landingpage\\src\\templates\\startTemplate.js" /* webpackChunkName: "component---src-templates-start-template-js" */),
  "component---src-pages-404-js": () => import("D:\\00079_Waldeck_Stadtverwaltung\\00079_1435_Klosterrundgang_Ober-Werbe\\Landingpage\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("D:\\00079_Waldeck_Stadtverwaltung\\00079_1435_Klosterrundgang_Ober-Werbe\\Landingpage\\.cache\\data.json")

